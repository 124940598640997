import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../elements/richText';
import ProofPoint from './proofPoint';

const StyledSection = styled.div`
  padding: 64px 0px;
  ${props =>
    props.background === 'White' &&
    css`
      background: #ffffff;
    `}

  ${props =>
    props.background === 'Red' &&
    css`
      background: ${props.theme.colours.nimbleRed};
    `}

  ${props =>
    props.background === 'Pink' &&
    css`
      background: ${props.theme.colours.pink};
    `}

  ${props =>
    props.background === 'Grey' &&
    css`
      background: ${props.theme.colours.greyBack};
    `}

  ${props =>
    props.background === 'Cream' &&
    css`
      background: ${props.theme.colours.cream};
    `}

  ${props =>
    props.background === 'Green' &&
    css`
      background: ${props.theme.colours.lightGreen};
    `}



  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 40px 0px;
  }

  ${props =>
    props.small &&
    css`
      padding: 40px 0px;

      @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
        padding: 24px 0px;
      }
    `}
`;

const StyledContainer = styled.div`
  display: grid;
  grid-gap: 0 35px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  padding: 0px 60px;
  max-width: 1403px;
  margin: auto;

  ${props =>
    props.small &&
    css`
      padding: 0 !important;
    `}

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    grid-gap: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 0px 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0px 24px;
  }
`;

const ProofPointsImage = styled.div`
  height: 0px;
  padding-top: 80%;
  background-size: cover;
  background-position: center;
  border-radius: 80px;
  margin-right: 45px;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    grid-column: 1 / 3;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    display: none;
  }
`;

const ProofPointsContent = styled.div`
  align-self: center;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    grid-column: 3 / 6;
  }
`;

const ProofPointsList = styled.div`
  display: grid;
  grid-gap: 24px 0;
  margin-top: 24px;

  ${props =>
    props.small &&
    css`
      margin-top: 0;
    `}

  @media (min-width: ${props => props.theme.breakpoints.min.lg}) {
    ${props =>
      props.noImage &&
      props.small &&
      css`
        grid-gap: 24px 48px;
        grid-template-columns: 1fr;
      `}
    ${props =>
      props.noImage &&
      !props.small &&
      css`
        grid-gap: 24px 48px;
        grid-template-columns: 1fr 1fr;
      `}
  }
`;

const StyledHeading = styled.h2`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 7px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledDescription = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 24px;

    @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default function ProofPoints({ data }) {
  const {
    backgroundColour,
    proofPointsHeading: heading,
    proofPointsDescription: description,
    image,
    points,
    small
  } = data;

  return (
    <StyledSection background={backgroundColour} small={small}>
      <StyledContainer small={small}>
        {image && (
          <ProofPointsImage
            style={{ backgroundImage: `url(${image.file.url})` }}
          />
        )}
        <ProofPointsContent>
          {heading && <StyledHeading>{heading}</StyledHeading>}
          {description && (
            <StyledDescription>
              <RichText text={description} />
            </StyledDescription>
          )}
          <ProofPointsList noImage={!image} small={small}>
            {points &&
              points.map(
                ({ heading: pointHeading, text, icon, iconBackground }) => {
                  return (
                    <ProofPoint
                      key={pointHeading}
                      small={small}
                      heading={pointHeading}
                      text={text}
                      icon={icon && icon.file.url}
                      iconBackground={iconBackground}
                    />
                  );
                }
              )}
          </ProofPointsList>
        </ProofPointsContent>
      </StyledContainer>
    </StyledSection>
  );
}

ProofPoints.propTypes = {
  data: PropTypes.shape({
    backgroundColour: PropTypes.string,
    proofPointsHeading: PropTypes.string,
    proofPointsDescription: PropTypes.objectOf(PropTypes.string),
    image: PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.string)
    }),
    small: PropTypes.boolean,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.shape({
          file: PropTypes.objectOf(PropTypes.string)
        }),
        iconBackground: PropTypes.string
      })
    )
  })
};
