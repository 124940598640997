import React, { useEffect } from 'react';
import { render } from 'react-dom';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Logo from './components/logo.components';
import MajorLinksColumn from './components/majorLinks.components';
import PreFooter from './components/preFooter.components';
import { AllContentfulTooltips } from '../../hooks/tooltips/tooltips';
import Tooltip from '../../components/tooltip';
import ApplyEligibilityPopup from '../applyEligibilityPopup';
import { routes } from '../../shared/constants';

const StyledSection = styled.footer`
  background: ${props => props.theme.colours.slate};
  padding: 100px 0px 40px;

  @media print {
    display: none !important;
  }
`;
const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0px 60px;
  max-width: 1403px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 0px 40px;
    display: block;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0px 30px;
  }
`;

const MajorLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const MajorLinkSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`;

const CopyRightContent = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 75px;
  text-align: left;

  a {
    color: #ffffff;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    margin-top: 50px;
    text-align: center;
  }
`;

const MobileLogo = styled.div`
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.min.md}) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 80%;
  height: auto;
  max-width: 150px;
  margin-top: 100px;
`;

const LegalLinksSection = styled.div`
  margin: 80px -15px 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    display: block;
    text-align: center;
  }
`;

const LegalTitle = styled.h2`
  flex: 0 0 100%;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 0 15px;
  box-sizing: border-box;
`;

const LegalLink = styled.a`
  display: block;
  flex: 0 0 25%;
  font-size: 12px;
  line-height: 20px;
  color: rgb(189, 189, 189);
  margin-bottom: 8px;
  padding: 0 15px;
  box-sizing: border-box;
  transition: 0.35s ease;

  &:hover {
    color: #ffffff;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    flex: 0 0 33.333%;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    display: inline-block;
    position: relative;

    &::before {
      content: '|';
      position: absolute;
      left: -1.5px;
    }

    &::after {
      content: '|';
      position: absolute;
      right: -1px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    display: block;

    &::before,
    &::after {
      content: none;
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterDataQuery {
      contentfulNimbleFooterSection(
        componentId: { eq: "Nimble Main Footer Section" }
      ) {
        id
        nimbleFooterLogo {
          file {
            url
          }
        }
        mainFooterLinks {
          title
          components {
            ... on ContentfulNimbleSection {
              id
              components {
                ... on ContentfulLink {
                  id
                  linkText
                  linkUrl
                }
              }
              title
            }
          }
        }
        popularSearches {
          title
          components {
            ... on ContentfulLink {
              id
              linkText
              linkUrl
            }
          }
        }
        legalLinks {
          title
          components {
            ... on ContentfulLink {
              id
              linkText
              linkUrl
            }
          }
        }
        socialMediaLinks {
          title
          components {
            ... on ContentfulLink {
              id
              linkText
              linkUrl
              linkIcon {
                file {
                  url
                }
              }
            }
          }
        }
        copyrightText
        customerServicePhoneNumber
        customerServicePhoneNumberText
      }
    }
  `);
  const {
    copyrightText,
    customerServicePhoneNumber,
    customerServicePhoneNumberText,
    mainFooterLinks,
    nimbleFooterLogo,
    socialMediaLinks,
    popularSearches,
    legalLinks
  } = data.contentfulNimbleFooterSection;

  const currentYear = new Date().getFullYear();
  const currentCopyrightText = copyrightText.replace(
    '{currentyear}',
    currentYear
  );

  const tooltips = AllContentfulTooltips();

  useEffect(() => {
    setTimeout(() => {
      const tooltipContainers = Array.from(
        document.getElementsByClassName('nimble-tooltip')
      );
      if (tooltipContainers.length) {
        tooltipContainers.forEach(tooltipContainer => {
          const tooltipData = tooltips.filter(
            tooltip =>
              tooltip.tooltipId === tooltipContainer.getAttribute('data-number')
          );

          if (tooltipData.length) {
            render(
              <Tooltip text={tooltipData[0].tooltipText} />,
              tooltipContainer
            );
          }
        });
      }
    }, 750);
  }, [tooltips]);

  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupQuery, setPopupQuery] = React.useState(null);
  const OpenApplyPopup = (e, type) => {
    e.preventDefault();
    if (type === 'form') {
      const calculatorValues = new URLSearchParams(
        new FormData(e.currentTarget)
      );
      setPopupQuery(calculatorValues.toString());
    }
    if (type === 'link') {
      //In case original link has query string
      if (e.currentTarget.href && e.currentTarget.href.indexOf('?') !== -1) {
        const query = e.currentTarget.href.split('?').pop();
        query ? setPopupQuery(query) : setPopupQuery(null);
      }
    }

    setOpenPopup(true);
    document.documentElement.style.overflow = 'hidden';
  };

  useEffect(() => {
    setTimeout(() => {
      const allLinks = Array.from(document.getElementsByTagName('a'));
      const applyLinks = allLinks.reduce((links, link) => {
        if (
          link.href.indexOf(routes.global.urls.fsaNimble.fgfPopUpApplyUrl) !==
          -1
        ) {
          links.push(link);
        }
        return links;
      }, []);

      if (applyLinks.length) {
        applyLinks.forEach(applyLink => {
          applyLink.addEventListener('click', e => OpenApplyPopup(e, 'link'));
        });
      }

      const allForms = Array.from(document.getElementsByTagName('form'));
      const applyForms = allForms.reduce((forms, form) => {
        if (
          form.action.indexOf(routes.global.urls.fsaNimble.fgfPopUpApplyUrl) !==
          -1
        ) {
          forms.push(form);
        }
        return forms;
      }, []);

      if (applyForms.length) {
        applyForms.forEach(applyForm => {
          applyForm.addEventListener('submit', e => OpenApplyPopup(e, 'form'));
        });
      }
    }, 1000);
  }, []);

  return (
    <>
      <ApplyEligibilityPopup
        isOpen={openPopup}
        setIsOpen={setOpenPopup}
        query={popupQuery}
      />
      <PreFooter socialMediaLinksData={socialMediaLinks} />
      <StyledSection>
        <StyledContainer>
          <MajorLinkSection>
            <MajorLinkContainer logoContainer>
              <Logo
                footerLogo={nimbleFooterLogo}
                phoneNumber={customerServicePhoneNumber}
                phoneNumberText={customerServicePhoneNumberText}
              />
            </MajorLinkContainer>
            <>
              {mainFooterLinks.components.map((linkgroup, index) => {
                return (
                  <MajorLinksColumn
                    linkgroup={linkgroup}
                    number={index}
                    key={linkgroup.id}
                  />
                );
              })}
            </>
          </MajorLinkSection>
          <MobileLogo>
            <a href="/">
              <StyledLogo src={nimbleFooterLogo.file.url} alt="nimble logo" />
            </a>
          </MobileLogo>
          <LegalLinksSection>
            <LegalTitle>{popularSearches.title}</LegalTitle>
            {popularSearches.components.map(link => {
              return (
                <LegalLink href={link.linkUrl} key={link.id}>
                  {link.linkText}
                </LegalLink>
              );
            })}
          </LegalLinksSection>
          <CopyRightContent>{currentCopyrightText}</CopyRightContent>
          <LegalLinksSection>
            <LegalTitle>{legalLinks.title}</LegalTitle>
            {legalLinks.components.map(link => {
              return (
                <LegalLink href={link.linkUrl} key={link.id}>
                  {link.linkText}
                </LegalLink>
              );
            })}
          </LegalLinksSection>
        </StyledContainer>
      </StyledSection>
    </>
  );
};

export default Footer;
