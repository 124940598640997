import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ProofPoints from '../../components/proofPoints';
import Buttons from '../../components/buttons';
import RichText from '../../elements/richText';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import crossIcon from '../../../static/images/cross-blk.png';

const ModalOverideStyles = styled.span`
  button {
    background: none !important;
    border: none !important;
    cursor: pointer;
    display: inline-block;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 15px;
      height: 15px;
    }
  }
`;

const OverlayStyles = styled.span`
  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    max-width: 86%;
  }
  
  @media(max-width: ${props => props.theme.breakpoints.max.xs}){
    max-width: 92%;
  }

  &:focus{
    outline: none;
  }

  .icon-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 15px 10px 10px;

    .tooltip-button {
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      .cross-icon {
        width: 15px;
        height: auto;
      }
    }
  }
`;

const ModalContent = styled.span`
  display: block;
  position: relative;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
  padding: 45px 30px 16px;
  width: 100%;
  max-width: 600px;
  border-radius: 6px;
  box-sizing: border-box;

  ${props => props.background === 'White' && css`
    background-color: #ffffff;
  `}

  ${props => props.background === 'Red' && css`
    background-color: ${props.theme.colours.nimbleRed};
  `}

  ${props => props.background === 'Pink' && css`
    background-color: ${props.theme.colours.pink};
  `}

  ${props => props.background === 'Grey' && css`
    background-color: ${props.theme.colours.greyBack};
  `}

  ${props => props.background === 'Cream' && css`
    background-color: ${props.theme.colours.cream};
  `}

  ${props => props.background === 'Green' && css`
    background-color: ${props.theme.colours.lightGreen};
  `}

  @media(max-height: 600px){
    max-height: 80vh;
    overflow-y: scroll;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 40px 20px 10px;
  }
`;

const Note = styled.div`
  padding: 15px 24px 1px;
  border-radius: 4px;

  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
    margin-bottom: 14px;
  }
`;

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const ApplyEligibilityPopup = ({isOpen, setIsOpen, query}) => {
  const data = useStaticQuery(graphql`
    query ApplyEligibilityPopupQuery {
      contentfulNimbleSection(
        sectionId: { eq: "Global Apply Eligibility Popup" }
      ) {
        title
        subtitle
        sectionBackground
        components {
          ... on ContentfulComponentProofPoints {
            __typename
            proofPointsHeading: heading
            proofPointsDescription: description {
              raw
            }
            image {
              file {
                url
              }
            }
            backgroundColour
            small
            points {
              heading
              text
              icon {
                file {
                  url
                }
              }
              iconBackground
            }
          }
          ... on ContentfulNimbleSection {
            id
            __typename
            sectionBackground
            descriptionRichText {
              raw
            }
          }
          ... on ContentfulComponentButtons {
            id
            __typename
            buttonLinks {
              linkText
              linkUrl
              buttonColor
              openNewTab
            }
          }
        }
      }
    }
  `);
  const {
    title,
    subtitle,
    sectionBackground,
    components
  } = data.contentfulNimbleSection;
  const proofPointsData = components.filter(
    data => data.__typename === 'ContentfulComponentProofPoints'
  )[0];

  const noteData = components.filter(
    data => data.__typename === 'ContentfulNimbleSection'
  )[0];

  const buttonsData = components.filter(
    data => data.__typename === 'ContentfulComponentButtons'
  )[0];

  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(false);
    document.documentElement.removeAttribute('style');
  };

  return (
    <ModalOverideStyles>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <OverlayStyles>
            <ModalContent className={classes.paper} background={sectionBackground}>
              <span id="transition-modal-description">
                {title && <h2>{title}</h2>}
                {subtitle && <h3>{subtitle}</h3>}
                {proofPointsData && <ProofPoints data={proofPointsData} />}
                {noteData && (
                  <Note
                    className={`component-background_${
                      noteData.sectionBackground
                        ? noteData.sectionBackground
                        : null
                    }`}
                  >
                    <RichText text={noteData.descriptionRichText} />
                  </Note>
                )}
                {buttonsData && <Buttons data={buttonsData} query={query} />}
              </span>
              <span className="icon-wrapper">
                <button
                  type="button"
                  className="tooltip-button"
                  onClick={handleClose}
                  aria-label="Close Tooltip"
                >
                  <img className="cross-icon" src={crossIcon} alt="exit icon" />
                </button>
              </span>
            </ModalContent>
          </OverlayStyles>
        </Fade>
      </Modal>
    </ModalOverideStyles>
  );
};

export default ApplyEligibilityPopup;
