import React from 'react';
import styled from 'styled-components';
import Button from '../../elements/button';
import { routes } from '../../shared/constants';

const Buttons = ({ data, query }) => {
  const {
    componentBackground,
    componentStyleClasses,
    buttonLinks,
    print
  } = data;

  const ButtonsContainer = styled.div`
    max-width: ${props => props.theme.components.componentStandardWidthMax};
    margin: 0 auto;
    text-align: center;
    padding: 40px 20px 20px;

    &.Padding-Top-None {
      padding-top: 0;
    }

    &.Padding-Bottom-None {
      padding-bottom: 0;
    }

    &.Font-Size-Large {
      a.component-button {
        margin-top: 30px !important;
        font-size: 30px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
    }

    a.component-button {
      margin: 0 20px 20px;

      @media (max-width: 500px) {
        margin: 0 0 20px;
      }
    }
  `;

  const PrintButton = styled.input`
    @media print {
      display: none !important;
    }
  `;

  return (
    <ButtonsContainer
      className={`component component-buttons component-background_${
        componentBackground ? componentBackground : ''
      } ${componentStyleClasses ? componentStyleClasses.join(' ') : ''}`}
    >
      {buttonLinks &&
        !print &&
        buttonLinks.map(
          ({
            linkText,
            buttonColor,
            openNewTab,
            linkUrl,
            relativePathLink
          }) => {
            return (
              <Button
                key={linkText}
                linkText={linkText}
                buttonColor={buttonColor}
                openNewTab={openNewTab}
                linkUrl={
                  query &&
                  linkUrl.indexOf(routes.global.urls.fsaNimble.fgfPopUpApplyUrl) !== -1
                    ? `${linkUrl}?${query}`
                    : linkUrl
                }
                relativePathLink={relativePathLink}
              />
            );
          }
        )}
      {data.print && (
        <PrintButton
          type="button"
          className="component-button button-color_Green"
          onClick={() => window.print()}
          value="Print"
        />
      )}
    </ButtonsContainer>
  );
};

export default Buttons;
