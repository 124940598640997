import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Head from '../globalComponents/head';
import Components from '../components/components';
import StaticPages from '../staticPages';
import NimbleHeader from '../globalComponents/header';
import NimbleHeaderNoNav from '../globalComponents/headerNoNav';
import NimbleFooter from '../globalComponents/footer';
import FooterDisclaimers from '../globalComponents/footerDisclaimers';

const HeaderBuffer = styled.div`
  padding-top: 30px;
  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding-top: 65px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding-top: 35px;
  }

  @media print {
    padding-top: 0 !important;
  }
`;

const App = ({ data }) => {
  const {
    pageTitle,
    googleOptimize,
    metaDescription,
    canonicalUrl,
    components,
    staticPage,
    footerDisclaimers,
    noIndex
  } = data.allContentfulComponentsPage.edges[0].node;

  let heroIsFirst = false;
  let buffer = false;

  if (
    components &&
    (components[0].__typename === 'ContentfulNimbleHero' ||
      components[0].__typename === 'ContentfulNimblePageHeadingSection' ||
      components[0].__typename === 'ContentfulComponentBanner')
  ) {
    heroIsFirst = true;
  }
  
  if (components && components[0].sectionBackground === null && components[0].componentBackground === null) {
    buffer = true;
  }

  const isStaticPage = staticPage
    ? JSON.parse(staticPage.internal.content).page
    : null;

  const noNavigation = staticPage
    ? JSON.parse(staticPage.internal.content).noNav
    : false;

  return (
    <>
      <Head
        pageTitle={pageTitle}
        metaDescription={metaDescription}
        googleOptimize={googleOptimize}
        canonicalUrl={canonicalUrl}
        noIndex={noIndex}
      />
      {noNavigation ? (
        <NimbleHeaderNoNav />
      ) : (
        <NimbleHeader heroIsFirst={heroIsFirst} />
      )}
      {heroIsFirst === false && buffer && <HeaderBuffer />}
      {isStaticPage ? (
        <StaticPages staticPage={staticPage} componentData={components} />
      ) : (
        <Components pageData={staticPage} components={components} />
      )}
      {footerDisclaimers && <FooterDisclaimers data={footerDisclaimers} />}
      <NimbleFooter />
    </>
  );
};

export default App;
export const query = graphql`
  query($contentful_id: String) {
    allContentfulComponentsPage(
      filter: { contentful_id: { eq: $contentful_id } }
    ) {
      edges {
        node {
          googleOptimize
          pageTitle
          metaDescription
          canonicalUrl
          noIndex
          staticPage {
            id
            internal {
              content
            }
          }
          ...ComponentsFragment
          footerDisclaimers {
            text {
              raw
            }
          }
        }
      }
    }
  }
`;
