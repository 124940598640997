import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import insertTooltip from '../../hooks/tooltips/insertTooltip';

const ProofPointContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProofPointIcon = styled.div`
  flex: 0 0 auto;
  align-self: flex-start;
  width: 56px;
  height: 56px;
  padding: 13px;
  margin-right: 16px;
  border-radius: 50%;

  ${props => props.background === 'White' && css`
    background-color: #ffffff;
  `}

  ${props => props.background === 'Red' && css`
    background-color: ${props.theme.colours.nimbleRed};
  `}

  ${props => props.background === 'Pink' && css`
    background-color: ${props.theme.colours.pink};
  `}

  ${props => props.background === 'Grey' && css`
    background-color: ${props.theme.colours.greyBack};
  `}

  ${props => props.background === 'Cream' && css`
    background-color: ${props.theme.colours.cream};
  `}

  ${props => props.background === 'Green' && css`
    background-color: ${props.theme.colours.lightGreen};
  `}

  ${props => props.small && css`
    width: 40px;
    height: 40px;
    padding: 8px;
  `}

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.xs}){
    width: 35px;
    height: 35px;
    padding: 6px;
  }
`;

const ProofPointIconImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const ProofPointContent = styled.div`
  flex: 1;
`;

const ProofPointHeading = styled.h3`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;

  ${props => props.small && css`
    font-size: 18px;
    line-height: 22px;
  `}

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    font-size: 16px;
    line-height: 20px;
  }
`;

const ProofPointText = styled.p`
  margin: 8px 0;
  line-height: 24px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    font-size: 14px;
    line-height: 20px;
  }
`;

export default function ProofPoint({
  heading,
  text,
  icon,
  iconBackground,
  small
}) {
  let newText = text;

  if (text && text.includes('{tooltip')) {
    newText = insertTooltip(text);
  }

  return (
    <ProofPointContainer>
      {icon && <ProofPointIcon background={iconBackground} small={small}><ProofPointIconImage style={{ backgroundImage: `url(${icon})` }} /></ProofPointIcon>}
      <ProofPointContent>
        {heading && <ProofPointHeading small={small}>{heading}</ProofPointHeading>}
        {newText && <ProofPointText>{newText}</ProofPointText>}
      </ProofPointContent>
    </ProofPointContainer>
  );
}

ProofPoint.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  iconBackground: PropTypes.string
};
