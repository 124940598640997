import React from 'react';

const Button = ({
  linkText,
  buttonColor,
  openNewTab,
  linkUrl,
  relativePathLink
}) => (
  <a
    className={`component-button button-color_${
      buttonColor ? buttonColor : null
    }`}
    href={linkUrl ? linkUrl : relativePathLink}
    target={openNewTab ? '_blank' : '_self'}
  >
    {linkText}
  </a>
);

export default Button;
